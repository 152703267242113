import type {GonObject} from '../types/gon';
import {get} from 'svelte/store';
import {isEmbedded, webComponentRoot} from 'Store/embedded/embeddedFlowStore';
import authManager from 'Utility/authManager';
import {sveltekitContext, API_HOST_V2_URL} from 'Store/sveltekit/store';
import {environmentVariables} from 'Store/environment';

const apiSvelteKitContent = (id: string) => {
  return get(sveltekitContext) && id === 'SVELTE_APP_API_HOST';
};

export const getElementById = (id: string): HTMLElement => {
  return getDocument().querySelector(`#${id}`);
};

export const getDocument = (): HTMLElement | Document => {
  return get(isEmbedded) ? get(webComponentRoot) : document;
};

export const getElementByQuerySelector = (selector: string): HTMLElement => {
  return getDocument().querySelector(selector);
};

export const getEnvironmentVariable = (id: string): string => {
  if (get(isEmbedded)) {
    return window.DealMakerCheckoutContext?.variables[id];
  } else if (apiSvelteKitContent(id) && window?.gon === undefined) {
    return API_HOST_V2_URL;
  } else if (get(environmentVariables) && window?.gon === undefined) {
    return get(environmentVariables)[id];
  } else {
    if (window?.gon === undefined) return '';

    return (window?.gon as GonObject)[id] as string;
  }
};

export const getCurrentUserId = (): number => {
  if (get(isEmbedded) || get(sveltekitContext)) {
    const token = authManager.getCurrentAccessToken();

    // TODO: Add token validation check
    if (!token) {
      return null;
    }

    return token.owner_id;
  } else {
    return Number(getEnvironmentVariable('current_user_id'));
  }
};

export const getWrapperElement = (): HTMLElement | Window => {
  return get(isEmbedded) ? get(webComponentRoot) : window;
};

export const getElementWidth = (): number => {
  return get(isEmbedded)
    ? get(webComponentRoot).clientWidth
    : window.innerWidth;
};

export const getAssetUrl = (asset: string): string => {
  return `${getEnvironmentVariable('SVELTE_APP_HOST')}${asset}`;
};
